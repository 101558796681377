@use '../../foundation/'as *;
@use '../../global/'as *;

.p-faq {
  padding-block: 30px 60px;

  @include responsive(md) {
    padding-block: 60px 100px;
  }

  &__wrap {
    padding: 10px;
    background-color: $bg-light-gray;
    border-radius: 10px;

    @include responsive(md) {
      padding: 20px;
    }

    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }

  &__q-wrap {
    padding-bottom: 10px;
  }

  &__q {
    display: flex;
    align-items: center;
    min-height: 40px;
    padding-left: 50px;
    background: url("../images/ico_q.png") no-repeat center left / 40px;
    color: $c-dark-blue;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;

    @include responsive(md) {
      font-size: 20px;
      line-height: 27px;
    }
  }

  &__a-wrap {
    margin-right: 1em;
    padding-top: 10px;
    border-top: 1px dashed $border-dark-gray;

    @include responsive(md) {
      margin-right: 0;
    }
  }

  &__a {
    min-height: 40px;
    padding-top: 10px;
    padding-left: 50px;
    line-height: 21px;
    background: url("../images/ico_a.png") no-repeat top left / 40px;

    @include responsive(md) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
