@use '../../foundation/'as *;
@use '../../global/'as *;

.p-company {
  padding-block: 40px 60px;

  @include responsive(md) {
    padding-block: 60px 100px;
  }

  &__greeting {
    margin-top: 30px;

    @include responsive(md) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__img-wrap {
    width: 69.6%;
    margin: 0 auto;
    aspect-ratio: 1 / 1;

    @include responsive(md) {
      width: 31.5%;
    }
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__greeting-wrap {
    margin-top: 30px;

    @include responsive(md) {
      width: 64.5%;
      margin-top: 40px;
    }
  }

  &__greeting-txt {
    line-height: 21px;
  }

  &__name-wrap {
    margin-top: 1em;
    text-align: right;
    font-weight: bold;

    @include responsive(md) {
      margin-top: 90px;
    }
  }

  &__name {
    font-size: 20px;
  }

  &__txt {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;

    @include responsive(md) {
      font-size: 20px;
      line-height: 27px;
    }
  }

  &__item {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;

    @include responsive(md) {
      font-size: 20px;
      line-height: 27px;
    }

    &:not(:first-of-type) {
      margin-top: 15px;

      @include responsive(md) {
        margin-top: 23px;
      }
    }
  }

  &__table {
    width: 100%;
  }

  &__tr,
  &__th,
  &__td {
    display: block;

    @include responsive(md) {
      border-collapse: collapse;
    }
  }

  &__tr {
    @include responsive(md) {
      display: table-row;
      border-top: 1px solid $border-gray;
      border-bottom: 1px solid $border-gray;
    }
  }

  &__th {
    padding: 16px 0 16px 20px;
    background-color: $bg-light-blue;

    @include responsive(md) {
      display: table-cell;
      padding-block: 22px;
      font-size: 20px;
      vertical-align: middle;
    }
  }

  &__td {
    padding-block: 18px;
    line-height: 21px;

    @include responsive(md) {
      display: table-cell;
      padding: 30px;
      vertical-align: middle;
    }
  }

  &__map {
    @include responsive(md) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__map-txt {
    margin-bottom: 7px;
    color: $c-blue;
    font-size: 20px;
    font-weight: bold;
    line-height: 27px;

    @include responsive(md) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  &__map-wrap {
    @include responsive(md) {
      width: 48.6%;
    }

    &:last-of-type {
      margin-top: 25px;

      @include responsive(md) {
        margin-top: 0;
      }
    }

    & iframe {
      width: 100%;
      aspect-ratio: 1 / 1;
    }
  }
}
