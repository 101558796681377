//margin(px)
$i : 0;
$j : 0;

@while ($i < 4) {
  //marginのクラス名
  $marginClass: "t";
  $direction: "top";

  @if ($i==1) {
    $marginClass: "b";
    $direction: "bottom";
  }

  @else if ($i==2) {
    $marginClass: "l";
    $direction: "left";
  }

  @else if ($i==3) {
    $marginClass: "r";
    $direction: "right";
  }

  //各向きのmarginを作成
  @while ($j <=100) {
    @if ($j==0) {
      .u-m#{$marginClass}-#{$j} {
        margin-#{$direction}: 0 !important;
      }
    }

    @else {
      .u-m#{$marginClass}-#{$j} {
        margin-#{$direction}: $j + px !important;
      }
    }

    $j : $j+5;
  }

  $i : $i+1;
  $j : 0;
}


//margin(em)
$i : 0;
$j : 0;

@while ($i < 4) {
  //marginのクラス名
  $marginClass: "t";
  $direction: "top";

  @if ($i==1) {
    $marginClass: "b";
    $direction: "bottom";
  }

  @else if ($i==2) {
    $marginClass: "l";
    $direction: "left";
  }

  @else if ($i==3) {
    $marginClass: "r";
    $direction: "right";
  }

  //各向きのmarginを作成
  @while ($j <=10) {
    .u-m#{$marginClass}-#{$j}em {
      margin-#{$direction}: $j + em !important;
    }

    $j : $j+1;
  }

  $i : $i+1;
  $j : 0;
}


//padding(px)
$i : 0;
$j : 0;

@while ($i < 4) {
  //padding
  $paddingClass: "t";
  $direction: "top";

  @if ($i==1) {
    $paddingClass: "b";
    $direction: "bottom";
  }

  @else if ($i==2) {
    $paddingClass: "l";
    $direction: "left";
  }

  @else if ($i==3) {
    $paddingClass: "r";
    $direction: "right";
  }

  //各向きのpaddingを作成
  @while ($j <=100) {
    @if ($j==0) {
      .u-p#{$paddingClass}-#{$j} {
        padding-#{$direction}: 0 !important;
      }
    }

    @else {
      .u-p#{$paddingClass}-#{$j} {
        padding-#{$direction}: $j + px !important;
      }
    }

    $j : $j+5;
  }

  $i : $i+1;
  $j : 0;
}


//padding(em)
$i : 0;
$j : 0;

@while ($i < 4) {
  //padding
  $paddingClass: "t";
  $direction: "top";

  @if ($i==1) {
    $paddingClass: "b";
    $direction: "bottom";
  }

  @else if ($i==2) {
    $paddingClass: "l";
    $direction: "left";
  }

  @else if ($i==3) {
    $paddingClass: "r";
    $direction: "right";
  }

  //各向きのpaddingを作成
  @while ($j <=10) {
    .u-p#{$paddingClass}-#{$j}em {
      padding-#{$direction}: $j + em !important;
    }

    $j : $j+1;
  }

  $i : $i+1;
  $j : 0;
}
