@use '../../foundation/'as *;
@use '../../global/'as *;

.c-icon{
  &__external-link{
    display: inline-block;
    padding-right: 26px;
    background: url("../images/top/ico_external-link.svg") no-repeat right center;
  }
}
