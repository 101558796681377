@use '../../foundation/'as *;
@use '../../global/'as *;

.p-antisocial {
  padding-block: 30px 60px;

  @include responsive(md) {
    padding-block: 60px 100px;
  }

  &__item {
    padding: 20px;
    background-color: $bg-light-blue;

    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }

  &__txt {
    display: inline-block;
    margin-left: 0.8em;
    text-indent: -0.8em;
    font-size: 18px;
    line-height: 24px;
  }
}
