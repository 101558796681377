//---------color--------------------
$c-main: #333;
$c-white: #FFFFFF;
$c-accent: #FF7F00;
$c-blue: #1D60CC;
$c-dark-blue: #003D9D;

//---------background color---------
$bg-white: #FFFFFF;
$bg-orange: #FF7F00;
$bg-lighter-gray: #F5F5F5;
$bg-light-gray: #F2F2F2;
$bg-gray: #F4F4F4;
$bg-blue: #1D60CC;
$bg-light-blue: #E9F1F4;
$bg-table-blue: #E0EDFA;

//---------border color-------------
$border-main: #333;
$border-gray: #DBDBDB;
$border-dark-gray: #8E8D8D;
$border-lighter-blue: #AEBBE0;
$border-blue: #1D60CC;
$border-orange: #FF7F00;
