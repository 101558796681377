@use '../../foundation/'as *;
@use '../../global/'as *;

.p-hamburger {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: $bg-orange;
  border-radius: 10px;
  cursor: pointer;

  &__line-wrap {
    position: absolute;
    top: 10.5px;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 16px;

    span {
      display: inline-block;
      position: absolute;
      width: 20px;
      height: 2px;
      background-color: $bg-white;
      transition: var(--animation-normal);

      &:nth-of-type(2) {
        top: 6px;
      }

      &:nth-of-type(3) {
        width: 12.5px;
        top: 12px;
      }
    }

    &.active {
      span {
        &:nth-of-type(1) {
          top: 5px;
          transform: rotate(45deg);
        }

        &:nth-of-type(2) {
          opacity: 0;
        }

        &:nth-of-type(3) {
          width: 20px;
          top: 5px;
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__txt {
    position: absolute;
    bottom: 8.5px;
    width: 100%;
    color: $c-white;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
  }
}

// hamburger menu
.p-hamburger-menu {
  position: fixed;
  top: -120%;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 70px;
  background-color: $bg-gray;
  z-index: var(--z-hamburger-menu);
  overflow-y: auto;
  transition: var(--animation-hamburgerMenu);

  @include responsive(lg) {
    height: auto;
    padding-block: 37px 38px;
  }

  &.active {
    top: 60px;

    @include responsive(md) {
      top: 80px;
    }
  }

  &__link-wrap {
    width: 100%;
    padding-block: 18px;
    border-bottom: 1px solid $border-gray;

    @include responsive(lg) {
      padding-block: 0;
      border-bottom: none;
    }
  }

  &__top-link {
    display: inline-block;
    position: relative;
    margin-top: 9px;
    padding-left: 16px;
    font-weight: bold;
    line-height: 21px;

    @include responsive(lg) {
      margin-top: 0;
    }

    &::before,
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      left: 0;
      width: 8px;
      height: 1px;
      border-radius: 2px;
      background-color: $bg-orange;
    }

    &::before {
      top: calc(50% - 3px);
      transform: rotate(45deg);
    }

    &::after {
      bottom: calc(50% - 3px);
      transform: rotate(-45deg);
    }
  }

  &__list {
    @include responsive(lg) {
      display: flex;
      margin-top: 25px;
    }
  }

  &__item {
    @include responsive(lg) {
      width: 25%;
    }
  }

  &__item-link-wrap {
    width: 100%;
    padding-block: 18px;
    border-bottom: 1px solid $border-gray;

    @include responsive(lg) {
      padding-block: 0;
      border-bottom: none;
    }

    &:not(:first-of-type) {
      @include responsive(lg) {
        margin-top: 25px;
      }
    }
  }

  &__link {
    display: inline-block;
    position: relative;
    padding-left: 16px;
    font-weight: bold;
    line-height: 21px;

    &::before,
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      left: 0;
      width: 8px;
      height: 1px;
      border-radius: 2px;
      background-color: $bg-orange;
    }

    &::before {
      top: calc(50% - 3px);
      transform: rotate(45deg);
    }

    &::after {
      bottom: calc(50% - 3px);
      transform: rotate(-45deg);
    }
  }

  &__sub-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    margin-top: 15px;
    margin-left: 16px;

    @include responsive(lg) {
      display: block;
      margin-top: 0;
    }
  }

  &__sub-item {
    width: 50%;
    font-size: 14px;
    line-height: 19px;

    @include responsive(lg) {
      width: 100%;
      margin-top: 10px;
    }

    &:nth-of-type(n+3) {
      margin-top: 10px;
    }
  }

  &__sub-link {
    text-decoration: underline;
  }

  &__bottom-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 38px;
  }

  &__bottom-item {
    font-size: 12px;
    line-height: 16px;

    &:first-of-type {
      margin-right: 55px;
    }
  }

  &__bottom-link {
    text-decoration: underline;
  }
}
