@use '../../foundation/'as *;
@use '../../global/'as *;

.u-font {
  &__link {
    display: inline-block;
    color: #133ED3;
    text-decoration: underline;
  }

  &__black {
    color: #333333
  }

  &__normal {
    font-weight: normal;
  }

  &__medium {
    font-weight: 500;
  }

  &__semi-bold {
    font-weight: 600;
  }

  &__bold {
    font-weight: bold;
  }

  &__under-line{
    text-decoration: underline;
  }

  &__13px {
    font-size: 13px;
    line-height: 1.5;
  }

  &__14px {
    font-size: 14px;
    line-height: 1.5;
  }

  &__16px {
    font-size: 16px;
  }

  &__18px {
    font-size: 18px;
    line-height: 1.33;
  }

  &__20px {
    font-size: 20px;
  }

  &__24px {
    font-size: 24px;
  }

  &__indent-1em {
    & li {
      padding-left: 1em;
      text-indent: -1em;
    }
  }

  &__text-indent {
    padding-left: 1em;
    text-indent: -1em;
  }

  &__text-indent-negative {
    text-indent: -1em;
  }

  &__word-break {
    word-break: break-all;
  }
}
