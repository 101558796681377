@use '../../foundation/'as *;
@use '../../global/'as *;

.p-news {
  padding-block: 40px 60px;

  @include responsive(md) {
    padding-block: 60px 100px;
  }

  &__item {
    border-top: 1px solid $border-gray;

    &:last-of-type {
      border-bottom: 1px solid $border-gray;
    }
  }

  &__link {
    display: block;
    padding: 15px 50px 15px 0;
    background: url("../images/top/ico_arrow-circle-right.png") no-repeat center right / 30px;

    @include responsive(md) {
      padding: 30px 95px 30px 0;
      background-position: center right 15px;
      transition: var(--animation-normal);
    }

    &:hover {
      @include responsive(md) {
        background-position: center right 5px;
        opacity: 1;

        .p-news__date,
        .p-news__txt {
          color: $c-accent;
        }
      }
    }
  }

  &__date {
    color: #7E7E7E;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;

    @include responsive(md) {
      font-size: 14px;
      line-height: 28px;
    }
  }

  &__txt {
    margin-top: 5px;
    font-size: 14px;
    line-height: 24px;

    @include responsive(md) {
      margin-top: 8px;
      font-size: 16px;
      line-height: 28px;
    }
  }
}

.p-news-pagination {
  margin-top: 40px;

  @include responsive(md) {
    margin-top: 60px;
  }

  &__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .page-numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 2px;
    color: $c-main;
  }

  .current {
    background: $bg-orange;
    color: $c-white;
  }

  .dots {
    background: transparent;
  }

  .prev {
    display: block;
    position: relative;
    width: auto;
    min-width: 50px;
    height: auto;
    background: transparent;
    color: $c-accent;
    margin-right: 10px;
    padding: 0 0 0 15px;

    &::before {
      content: '<';
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .next {
    position: relative;
    min-width: 50px;
    width: auto;
    height: auto;
    margin-left: 10px;
    padding: 0 15px 0 0;
    background: transparent;
    color: $c-accent;

    &::after {
      content: '>';
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}

.p-news-detail {
  padding-block: 40px 60px;

  @include responsive(md) {
    padding-block: 60px 100px;
  }

  &__breadcrumb {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__breadcrumb-item {
    width: 11em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include responsive(md) {
      width: 21em;
    }
  }

  &__breadcrumb-link {
    &::after {
      right: 4px;
    }
  }

  &__contents {
    margin-top: 40px;

    @include responsive(md) {
      margin-top: 60px;
    }
  }

  & a {
    text-decoration: underline;
  }

  & img {
    display: block;
    margin: 0 auto;
    width: auto !important;
  }

  &__button-wrap {
    margin-top: 60px;
    text-align: center;

    @include responsive(md) {
      margin-top: 40px;
    }
  }

  &__button {
    display: inline-block;
    padding: 12px 64px 12px 20px;
    margin: 0 auto;
    background: $bg-orange url("../images/top/ico_arrow-circle-right_white.png") no-repeat center right 20px / 20px;
    border: 3px solid $border-orange;
    border-radius: 10px;
    color: $c-white;
    font-size: 16px;
    line-height: 30px;
    text-decoration: none !important;

    @include responsive(md) {
      &:hover {
        color: $c-accent;
        background: $bg-white url("../images/top/ico_arrow-circle-right.png") no-repeat center right 20px / 20px;
        opacity: 1;
      }
    }
  }
}
