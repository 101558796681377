@use '../../foundation/'as *;
@use '../../global/'as *;

.u-display-pc {
  display: none;

  @include responsive(md) {
    display: block;
  }
}

.u-display-sp {
  @include responsive(md) {
    display: none;
  }
}

.u-display-pc-lg {
  display: none;

  @include responsive(lg) {
    display: block;
  }
}

.u-display-sp-lg {
  @include responsive(lg) {
    display: none;
  }
}

.u-block {
  display: block;
}

.u-inline {
  display: inline;
}

.u-inline-block {
  display: inline-block;
}

.u-flex {
  display: flex;

  &__flex-start {
    display: flex;
    align-items: flex-start;
  }

  &__align-center {
    display: flex;
    align-items: center;
  }
}

.u-flex-pc {
  display: none;

  @include responsive(md) {
    display: flex;
  }
}

.u-flex-sp {
  display: flex;

  @include responsive(md) {
    display: none;
  }
}

.u-flex-pc-lg {
  display: none;

  @include responsive(lg) {
    display: flex;
  }
}

.u-flex-sp-lg {
  display: flex;

  @include responsive(lg) {
    display: none;
  }
}

.u-flex-pc-xl {
  display: none;

  @include responsive(xl) {
    display: flex;
  }
}

.u-flex-sp-xl {
  display: flex;

  @include responsive(xl) {
    display: none;
  }
}

.u-table-sp {
  @include responsive(md) {
    display: none;
  }
}

.u-table-pc {
  display: none;

  @include responsive(md) {
    display: table;
  }
}
