@use '../foundation/'as *;
@use '../global/'as *;

.l-footer {
  &__wrap {
    padding-block: 30px;
    background-color: $bg-blue;

    @include responsive(md) {
      padding-block: 40px 60px;
    }
  }

  &__logo-wrap {
    text-align: center;
  }

  &__logo-link {
    display: inline-block;
    width: 260px;
  }
}

.l-footer-address {
  margin-top: 30px;

  @include responsive(md) {
    display: flex;
    justify-content: space-between;
  }

  &__wrap {
    width: 100%;
    max-width: 375px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid $border-lighter-blue;

    @include responsive(md) {
      display: flex;
      justify-content: center;
      max-width: 540px;
      padding: 20px 15px;
    }

    @include responsive(lg) {
      padding: 20px;
    }

    &:last-of-type {
      margin-top: 20px;

      @include responsive(md) {
        margin-top: 0;
        margin-left: 15px;
      }

      @include responsive(lg) {
        margin-left: 30px;
      }
    }
  }

  &__inner {
    @include responsive(md) {
      max-width: 325px;
    }
  }

  &__top-txt {
    color: $c-white;
    font-size: 14px;
    line-height: 19px;

    @include responsive(md) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  &__office-txt {
    font-weight: bold;
  }

  &__phone-wrap {
    margin-top: 18px;
  }

  &__label {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 22px;
    min-width: 40px;
    margin-right: 10px;
    background-color: $bg-white;
    color: $c-blue;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
  }

  &__txt {
    color: $c-white;
    font-size: 14px;
    line-height: 19px;

    @include responsive(md) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  &__mail-wrap {
    display: flex;
    margin-top: 15px;
  }
}

.l-footer-nav {
  &__wrap {
    padding-block: 27px;
    background-color: $bg-gray;

    @include responsive(lg) {
      padding-block: 40px;
    }
  }

  &__link-wrap {
    display: none;

    @include responsive(lg) {
      display: block;
      border-bottom: none;
    }
  }

  &__top-link {
    @include responsive(lg) {
      display: inline-block;
      position: relative;
      padding-left: 16px;
      font-weight: bold;
      line-height: 21px;
      transition: var(--animation-normal);

      &:hover {
        color: $c-accent;
        opacity: 1;
      }
    }

    &::before,
    &::after {
      @include responsive(lg) {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        width: 8px;
        height: 1px;
        border-radius: 2px;
        background-color: $bg-orange;
      }
    }

    &::before {
      @include responsive(lg) {
        top: calc(50% - 3px);
        transform: rotate(45deg);
      }
    }

    &::after {
      @include responsive(lg) {
        bottom: calc(50% - 3px);
        transform: rotate(-45deg);
      }
    }
  }

  &__list {
    display: none;

    @include responsive(lg) {
      display: flex;
      margin-top: 25px;
    }
  }

  &__item {
    @include responsive(lg) {
      width: 25%;
    }
  }

  &__item-link-wrap {
    @include responsive(lg) {
      width: 100%;
      padding-block: 0;
      border-bottom: none;
    }

    &:not(:first-of-type) {
      @include responsive(lg) {
        margin-top: 25px;
      }
    }
  }

  &__link {
    @include responsive(lg) {
      display: inline-block;
      position: relative;
      padding-left: 16px;
      font-weight: bold;
      line-height: 21px;
      transition: var(--animation-normal);

      &:hover {
        color: $c-accent;
        opacity: 1;
      }
    }

    &::before,
    &::after {
      @include responsive(lg) {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        width: 8px;
        height: 1px;
        border-radius: 2px;
        background-color: $bg-orange;
      }
    }

    &::before {
      @include responsive(lg) {
        top: calc(50% - 3px);
        transform: rotate(45deg);
      }
    }

    &::after {
      @include responsive(lg) {
        bottom: calc(50% - 3px);
        transform: rotate(-45deg);
      }
    }
  }

  &__sub-list {
    @include responsive(lg) {
      max-width: 500px;
      margin-top: 0;
      margin-left: 16px;
      padding-bottom: 18px;
    }
  }

  &__sub-item {
    @include responsive(lg) {
      width: 100%;
      margin-top: 10px;
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__sub-link {
    @include responsive(lg) {
      text-decoration: underline;
      transition: var(--animation-normal);

      &:hover {
        color: $c-accent;
        opacity: 1;
      }
    }
  }

  &__bottom-list {
    display: flex;

    @include responsive(lg) {
      margin-top: 38px;
    }
  }

  &__bottom-item {
    &:first-of-type {
      margin-right: 40px;
    }
  }

  &__bottom-link {
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;

    @include responsive(md) {
      transition: var(--animation-normal);

      &:hover {
        color: $c-accent;
        opacity: 1;
      }
    }
  }
}

.l-footer-link {
  padding-block: 18px 19px;
  border-bottom: 1px solid $border-gray;

  &__link-txt {
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
  }
}

.l-footer-copyright {
  display: block;
  padding-block: 18px 19px;
  text-align: center;
  font-size: 10px;
  line-height: 14px;
}
