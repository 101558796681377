@use '../../foundation/'as *;
@use '../../global/'as *;

.u-align {
  &__left {
    text-align: left;
  }

  &__right {
    text-align: right;
  }

  &__center {
    text-align: center;
  }
}

.u-vertical-align {
  &__initial {
    vertical-align: initial !important;
  }

  &__top {
    vertical-align: top !important;
  }

  &__middle {
    vertical-align: middle !important;
  }

  &__bottom {
    vertical-align: bottom !important;
  }

  &__super {
    font-size: smaller;
    vertical-align: super !important;
  }

  &__sub {
    font-size: smaller;
    vertical-align: sub !important;
  }
}

.u-m0-auto {
  margin: 0 auto;
}
