@use '../../foundation/'as *;
@use '../../global/'as *;

.p-knowledge {
  padding-block: 30px 60px;

  @include responsive(md) {
    padding-block: 60px 100px;
  }

  &__img-wrap {
    margin-top: 30px;

    @include responsive(md) {
      width: 65.8%;
      margin: 60px auto 0;
    }
  }

  &__structure {
    margin-top: 35px;

    @include responsive(md) {
      margin-top: 55px;
    }
  }

  &__structure-title {
    margin-bottom: 17px;
    color: $c-blue;
    font-size: 20px;
    font-weight: bold;
    line-height: 27px;

    @include responsive(md) {
      font-size: 22px;
      line-height: 30px;
    }

    &:not(:first-of-type) {
      margin-top: 1em;

      @include responsive(md) {
        margin-top: 30px;
      }
    }
  }

  &__flow-inner-top {
    @include responsive(md) {
      display: flex;
    }
  }

  &__flow-inner {
    position: relative;
    margin-top: 38px;

    @include responsive(md) {
      display: flex;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: -28px;
      transform: translateX(-50%);
      width: 27px;
      height: 18px;
      background-color: $bg-blue;
      clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
    }
  }

  &__flow-top {
    padding: 8px 13px;
    background-color: $bg-blue;
    border-radius: 10px 10px 0 0;
    color: $c-white;
    font-weight: bold;
    line-height: 24px;

    @include responsive(md) {
      width: 114px;
      border-radius: 10px 0 0 10px;
      padding: 14px 12px;
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__flow-bottom {
    padding: 8px 13px;
    background-color: $bg-lighter-gray;
    border-radius: 0 0 10px 10px;
    font-weight: bold;
    line-height: 24px;

    @include responsive(md) {
      width: 100%;
      padding: 14px 20px;
      border-radius: 0 10px 10px 0;
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__flow-2col-container {
    display: flex;
    justify-content: space-between;
  }

  &__flow-2col-wrap {
    width: calc(calc(100% - 10px) / 2);

    @include responsive(md) {
      width: calc(calc(100% - 30px) / 2);
    }
  }

  &__flow-2col-txt {
    position: relative;
    margin-top: 38px;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    text-align: center;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: -28px;
      transform: translateX(-50%);
      width: 27px;
      height: 18px;
      background-color: $bg-blue;
      clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
    }
  }
}
