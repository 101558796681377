@use '../../foundation/'as *;
@use '../../global/'as *;

.p-customers {
  padding-block: 30px 60px;

  @include responsive(md) {
    padding-block: 60px 100px;
  }

  &__txt {
    margin-top: 30px;

    @include responsive(md) {
      margin-top: 60px;
    }
  }
}
