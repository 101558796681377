@use '../../foundation/'as *;
@use '../../global/'as *;

.p-policy {
  padding-block: 30px 60px;

  @include responsive(md) {
    padding-block: 90px 100px;
  }

  &__wrap {
    margin-top: 40px;

    @include responsive(md) {
      margin-top: 75px;
    }
  }

  &__list-bracket {
    margin-top: 30px;

    @include responsive(md) {
      margin-top: 40px;
    }
  }

  &__item-bracket {
    margin-left: 3em;
    text-indent: -3em;
    line-height: 21px;

    &:not(:first-of-type) {
      margin-top: 1em;
    }
  }

  &__item-circled {
    margin-left: 1em;
    text-indent: -1em;
  }

  &__item-alphabet {
    margin-left: 2em;
    text-indent: -2em;
  }

  &__item-heading {
    text-indent: 0;
  }

  &__link-wrap {
    margin-top: 1em;
    word-break: break-all;
  }

  &__link {
    text-decoration: underline;
  }

  &__email-wrap {
    display: flex;
    align-items: center;
  }

  &__mail-img {
    width: 145px;
    margin-left: 0.5em;
    transform: translateY(-2px);
  }
}
