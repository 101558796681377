@use '../foundation/'as *;
@use '../global/'as *;

.l-main {
  margin-top: 60px;

  @include responsive(md) {
    margin-top: 80px;
  }
}
