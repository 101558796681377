@use '../../foundation/'as *;
@use '../../global/'as *;

.c-title {
  &__h2 {
    position: relative;
    margin-bottom: 25px;
    padding-top: 5px;
    font-size: 20px;
    font-weight: bold;
    line-height: 27px;

    @include responsive(md) {
      margin-bottom: 40px;
      padding-top: 7px;
      font-size: 24px;
      line-height: 32px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 25px;
      height: 1px;
      background-color: $border-blue;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: calc(100% - 30px);
      height: 1px;
      background-color: $border-gray;
    }

    &:nth-child(n+2) {
      margin-top: 60px;
    }
  }

  &__h3 {
    margin-bottom: 17px;
    color: $c-blue;
    font-size: 20px;
    font-weight: bold;
    line-height: 27px;

    @include responsive(md) {
      font-size: 22px;
      line-height: 30px;
    }
  }
}
