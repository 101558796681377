@use '../global/'as *;

:root {
  //---------フォント設定---------
  --base-font-size: 1rem;
  --base-font-weight: 500;
  --base-line-height: 1.75;
  //---------フォントファミリー設定---------
  --base-font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
  //--------z-index設定--------
  --z-header: 50;
  --z-hamburger-menu: 20;
  //--------animation設定--------
  --animation-normal: all 0.2s ease-in;
  --animation-hamburgerMenu: 0.3s;
}

html {
  @include responsive(sm) {
    margin: 0;
  }

  @include responsive(md) {
    margin: 0;
  }

  @include responsive(lg) {
    margin: 0;
  }

  @include responsive(xl) {
    margin: 0;
  }

  @include responsive(xxl) {
    margin: 0;
  }

  @include responsive(md) {
    scroll-padding-top: 50px;
  }
}

body {
  font-family: var(--base-font-family);
  font-size: var(--base-font-size);
  font-weight: var(--base-font-weight);
  line-height: var(--base-line-height);
  scroll-behavior: smooth;
}

a {
  transition: var(--animation-normal);

  &:hover {

    @include responsive(md) {
      opacity: 0.7;
    }
  }
}

img {
  width: 100%;
}
