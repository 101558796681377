@use '../../foundation/'as *;
@use '../../global/'as *;

.c-breadcrumb {
  text-align: right;

  &__list {
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;

    @include responsive(md) {
      margin-top: 21px;
    }
  }

  &__item {
    display: inline-block;
  }

  &__link {
    position: relative;
    padding-right: 1em;
    text-decoration: underline;

    &::after {
      content: '';
      position: absolute;
      top: 5px;
      right: 2px;
      width: 6px;
      height: 6px;
      border-top: solid 1px $c-main;
      border-right: solid 1px $c-main;
      transform: rotate(45deg);
    }
  }
}
