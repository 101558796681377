//---------------------------------------------------------------------------------------------
//ブレークポイント指定基本min-width
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$breakpoints: (
  "xs": (
    min-width: 0,
  ),
  "sm": (
    min-width: $sm,
  ),
  "md": (
    min-width: $md,
  ),
  "lg": (
    min-width: $lg,
  ),
  "xl": (
    min-width: $xl,
  ),
  "xxl": (
    min-width: $xxl,
  ),
) !default;
@mixin responsive($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
}
