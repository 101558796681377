@use '../../foundation/'as *;
@use '../../global/'as *;

.p-top-kv {
  &__wrap {
    position: relative;
  }

  &__img-wrap {
    width: 100%;
    height: 100%;
    aspect-ratio: 375 / 386;

    @include responsive(md) {
      height: calc(100vh - 130px);
      max-height: 900px;
      aspect-ratio: auto;
    }
  }

  @keyframes fadeZome {
    0% {
      transform: scale(1.15);
    }

    100% {
      transform: scale(1);
    }
  }

  .swiper-slide-active .p-top-kv__img-wrap,
  .swiper-slide-duplicate-active .p-top-kv__img-wrap,
  .swiper-slide-prev .p-top-kv__img-wrap {
    animation: fadeZome 7s linear 0s normal both;
  }

  &__img-sp {
    height: 100%;
    object-fit: cover;

    @include responsive(md) {
      display: none;
    }
  }

  &__img-pc {
    display: none;

    @include responsive(md) {
      display: block;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 15px;
    color: $c-white;
    font-size: 20px;
    line-height: 27px;
    z-index: 1;

    @include responsive(sm) {
      font-size: 24px;
    }

    @include responsive(md) {
      left: 50px;
    }

    @include responsive(lg) {
      font-size: 36px;
      line-height: 54px;
    }

    @include responsive(xl) {
      left: 110px;
    }
  }
}

.p-top-products {
  padding: 7px 15px 12px;
  background-color: $bg-gray;

  @include responsive(md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 90%;
    margin-top: -70px;
    margin-left: auto;
    padding: 10px;
    z-index: 1;

    @include responsive(lg) {
      width: 90%;
    }

    @include responsive(xl) {
      width: 87%;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;

    @include responsive(md) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 13%;
    }

    @include responsive(lg) {
      font-size: 20px;
      line-height: 27px;
    }
  }

  &__container {
    max-width: 500px;
    margin: 7px auto 0;

    @include responsive(md) {
      width: 86%;
      max-width: none;
      margin: 0 0 0 auto;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    width: calc(calc(100% - 10px) / 2);
    border: 1px solid $border-blue;
    border-radius: 10px;
    background-color: $bg-white;

    @include responsive(md) {
      width: calc(calc(100% - 30px) / 4);
    }

    @include responsive(xl) {
      width: calc(calc(100% - 45px) / 4);
    }

    &:nth-of-type(n+3) {
      margin-top: 10px;

      @include responsive(md) {
        margin-top: 0;
      }
    }
  }

  &__link {
    display: block;
    padding-block: 41px 14px;
    border: 2px solid transparent;
    border-radius: 10px;
    color: $c-blue;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    text-align: center;

    @include responsive(md) {
      transition: var(--animation-normal);

      &:hover {
        border: 2px solid $border-blue;
        opacity: 1;
      }
    }

    @include responsive(lg) {
      padding-block: 62px 8px;
      background-size: 50px 30px;
      background-position: center top 19px;
      font-size: 18px;
      line-height: 24px;
    }

    &--01 {
      background: url("../images/top/ico_product_01.svg") no-repeat center top 15px / 33px 20px;
    }

    &--02 {
      background: url("../images/top/ico_product_02.svg") no-repeat center top 15px / 33px 20px;
    }

    &--03 {
      background: url("../images/top/ico_product_03.svg") no-repeat center top 15px / 33px 20px;
    }

    &--04 {
      background: url("../images/top/ico_product_04.svg") no-repeat center top 15px / 33px 20px;
    }
  }
}

.p-top-news {
  padding-block: 52px;

  @include responsive(md) {
    padding-block: 80px 50px;
  }

  &__wrap-sp {
    @include responsive(md) {
      display: none;
    }
  }

  &__wrap-pc {
    display: none;

    @include responsive(md) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__title {
    position: relative;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;

    @include responsive(md) {
      margin-top: 15px;
      font-size: 32px;
      line-height: 43px;
    }

    &::before {
      content: "NEWS";
      position: absolute;
      top: -23px;
      color: $c-blue;
      font-size: 12px;
      font-weight: 400;
      font-family: 'Lato', sans-serif;

      @include responsive(md) {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }

  &__list {
    margin-top: 27px;

    @include responsive(md) {
      width: 74.3%;
      margin-top: 0;
    }
  }

  &__item {
    border-top: 1px solid $border-gray;

    &:last-of-type {
      border-bottom: 1px solid $border-gray;
    }
  }

  &__link {
    display: block;
    padding: 15px 50px 15px 0;
    background: url("../images/top/ico_arrow-circle-right.png") no-repeat center right / 30px;

    @include responsive(md) {
      padding: 30px 95px 30px 0;
      background-position: center right 15px;
      transition: var(--animation-normal);
    }

    &:hover {
      @include responsive(md) {
        background-position: center right 5px;
        opacity: 1;

        .p-top-news__date,
        .p-top-news__txt {
          color: $c-accent;
        }
      }
    }
  }

  &__date {
    color: #7E7E7E;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;

    @include responsive(md) {
      font-size: 14px;
      line-height: 28px;
    }
  }

  &__txt {
    margin-top: 5px;
    font-size: 14px;
    line-height: 24px;

    @include responsive(md) {
      margin-top: 8px;
      font-size: 16px;
      line-height: 28px;
    }
  }

  &__button-wrap {
    margin-top: 30px;

    @include responsive(md) {
      margin-top: 40px;
    }
  }

  &__button {
    display: block;
    width: 207px;
    padding: 12px 50px 12px 20px;
    margin: 0 auto;
    background: $bg-orange url("../images/top/ico_arrow-circle-right_white.png") no-repeat center right 20px / 20px;
    border: 3px solid $border-orange;
    border-radius: 10px;
    color: $c-white;
    font-size: 14px;
    line-height: 20px;

    @include responsive(md) {
      width: 234px;
      font-size: 16px;
      line-height: 30px;

      &:hover {
        color: $c-accent;
        background: $bg-white url("../images/top/ico_arrow-circle-right.png") no-repeat center right 20px / 20px;
        opacity: 1;
      }
    }
  }
}

.p-top-service {
  padding-block: 46px 30px;
  background: url("../images/top/bg_service_sp.jpg") no-repeat center bottom / cover;

  @include responsive(md) {
    padding-block: 70px 110px;
  }

  &__title {
    position: relative;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;

    @include responsive(md) {
      margin-top: 15px;
      font-size: 32px;
      line-height: 43px;
    }

    &::before {
      content: "SERVICE";
      position: absolute;
      top: -23px;
      color: $c-blue;
      font-size: 12px;
      font-weight: 400;
      font-family: 'Lato', sans-serif;

      @include responsive(md) {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }

  &__list {
    margin: 30px auto 0;
    max-width: 500px;

    @include responsive(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: none;
      margin-top: 40px;
    }
  }

  &__item {
    border: 1px solid $border-blue;
    border-radius: 10px;
    background-color: $bg-white;

    @include responsive(md) {
      width: calc(calc(100% - 60px) / 3);
    }

    &:not(:first-of-type) {
      margin-top: 10px;

      @include responsive(md) {
        margin-top: 0;
      }
    }
  }

  &__link {
    display: block;
    padding-block: 24px;
    border: 2px solid transparent;
    border-radius: 10px;
    color: $c-blue;
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;
    text-align: center;

    @include responsive(md) {
      padding-block: 119px 35px;
      font-size: 18px;
      line-height: 27px;
      transition: var(--animation-normal);
      background-position: center top 40px;
      background-size: 54px 68px;

      &:hover {
        border: 2px solid $border-blue;
        opacity: 1;
      }
    }

    &--01 {
      background: url("../images/top/ico_service_01.svg") no-repeat left 20px center/ 27px 35px;
    }

    &--02 {
      background: url("../images/top/ico_service_02.svg") no-repeat left 20px center/ 27px 35px;
    }

    &--03 {
      background: url("../images/top/ico_service_03.svg") no-repeat left 20px center/ 27px 35px;
    }
  }
}

.p-top-about {
  padding-block: 40px;
  border-bottom: 1px solid $border-gray;

  @include responsive(md) {
    padding-block: 95px 100px;
    overflow-x: hidden;
  }

  &__wrap {
    @include responsive(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__inner {
    @include responsive(md) {
      width: 40%;
    }
  }

  &__title {
    position: relative;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;

    @include responsive(md) {
      margin-top: 15px;
      font-size: 32px;
      line-height: 43px;
    }

    &::before {
      content: "ABOUT";
      position: absolute;
      top: -23px;
      color: $c-blue;
      font-size: 12px;
      font-weight: 400;
      font-family: 'Lato', sans-serif;

      @include responsive(md) {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }

  &__list {
    margin-top: 30px;
  }

  &__item {
    border-top: 1px solid $border-gray;

    &:last-of-type {
      border-bottom: 1px solid $border-gray;
    }
  }

  &__link {
    display: block;
    padding-block: 23px;
    background: url("../images/top/ico_arrow-circle-right.png") no-repeat center right / 30px;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;

    @include responsive(md) {
      padding-block: 30px;
      background-position: center right 15px;
      font-size: 20px;
      line-height: 30px;
    }

    &:hover {
      @include responsive(md) {
        background-position: center right 5px;
        opacity: 1;
        color: $c-accent;
      }
    }
  }

  &__img-wrap {
    width: 104.3%;
    margin-top: 30px;

    @include responsive(md) {
      margin: 0 calc(50% - 49.7vw);
      width: 49vw;
    }
  }
}

.p-top-faq {
  padding-block: 25px 30px;

  &__wrap {
    padding-inline: 15px;

    @include responsive(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 950px;
      margin: 0 auto;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 490px;
    min-height: 83px;
    margin: 0 auto;
    padding-left: 96px;
    background: url("../images/top/ico_QandA.png") no-repeat center left / 76px;

    @include responsive(md) {
      max-width: 545px;
      margin: 0 auto 0 0;
      padding-right: 15px;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    line-height: 27px;

    @include responsive(md) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  &__txt {
    margin-top: 10px;
    line-height: 23px;

    @include responsive(md) {
      margin-top: 0;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__button-wrap {
    margin-top: 20px;
  }

  &__button {
    display: block;
    width: 220px;
    padding: 12px 50px 12px 20px;
    margin: 0 auto;
    background: $bg-orange url("../images/top/ico_arrow-circle-right_white.png") no-repeat center right 20px / 20px;
    border: 3px solid $border-orange;
    border-radius: 10px;
    color: $c-white;
    font-size: 14px;
    line-height: 20px;

    @include responsive(md) {
      width: 250px;
      font-size: 16px;
      line-height: 30px;

      &:hover {
        color: $c-accent;
        background: $bg-white url("../images/top/ico_arrow-circle-right.png") no-repeat center right 20px / 20px;
      }
    }
  }
}
