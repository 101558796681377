@use '../../foundation/'as *;
@use '../../global/'as *;

.p-products {
  padding-block: 30px 60px;

  @include responsive(md) {
    padding-block: 60px 100px;
  }

  &__tab-list {
    display: flex;
    justify-content: space-between;
  }

  &__tab-item {
    width: calc(calc(100% - 10px) / 3);

    @include responsive(md) {
      width: calc(calc(100% - 60px) / 3);
    }

    &.active {
      .p-products__tab {
        background-color: $bg-white;
        color: $c-blue;
      }
    }
  }

  &__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px 5px;
    border: 1px solid $border-blue;
    background-color: $bg-blue;
    color: $c-white;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    transition: var(--animation-normal);
    cursor: pointer;

    @include responsive(md) {
      padding: 17px 5px;
      font-size: 18px;
      line-height: 22px;
    }

    &:hover {
      opacity: 1;
      background-color: $bg-white;
      color: $c-blue;
    }
  }

  &__wrap {
    margin-top: 40px;

    @include responsive(md) {
      margin-top: 100px;
    }
  }

  &__content {
    display: none;

    &.active {
      display: block;
    }
  }

  &__item {
    position: relative;
    padding-left: 1.5em;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0.5em;
      width: 12px;
      height: 12px;
      border-radius: 50px;
      background-color: $bg-blue;
    }

    &:not(:first-of-type) {
      margin-top: 1em;
    }
  }

  &__table-scroll {
    overflow-x: scroll;

    @include responsive(md) {
      overflow-x: hidden;
    }
  }

  &__table {
    width: 1110px;
    margin-top: 60px;

    @include responsive(md) {
      width: 100%;
    }
  }

  &__th,
  &__col-th,
  &__td {
    border: 1px solid $border-gray;
    border-collapse: collapse;
    padding: 17px 10px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    vertical-align: middle;
  }

  &__th {
    background-color: $bg-blue;
    color: $c-white;
  }

  &__col-th {
    background-color: $bg-gray;
  }

  &__tr {
    &:nth-child(odd) {
      background-color: $bg-table-blue;
    }
  }

  &__first-th {
    background-color: $bg-white;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__inner {
    width: calc(calc(100% - 10px) / 2);

    @include responsive(md) {
      width: calc(calc(100% - 30px) / 4);
    }

    @include responsive(lg){
      width: calc(calc(100% - 90px) / 4);
    }

    &:nth-of-type(n+5) {
      @include responsive(md) {
        margin-top: 50px;
      }
    }
  }

  &__txt-wrap {
    margin-top: 20px;
  }

  &__heading {
    padding-bottom: 7px;
    border-bottom: 1px solid $border-blue;
    color: $c-blue;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;

    @include responsive(md) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  &__detail-list {
    min-height: 172px;
    list-style: disc;
    margin-top: 20px;
    margin-left: 1.5em;
    font-size: 14px;
    line-height: 19px;

    @include responsive(md) {
      font-size: 16px;
      line-height: 21px;
    }
  }
}
