@use '../foundation/'as *;
@use '../global/'as *;

.l-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--z-header);

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 60px;
    padding-inline: 5px;
    background-color: $bg-white;
    z-index: var(--z-header);

    @include responsive(md) {
      height: 80px;
    }
  }

  &__logo-wrap {
    width: 200px;

    @include responsive(md) {
      width: 277px;
    }
  }

  &__logo-link {
    &:hover {
      opacity: 1;
    }
  }

  &__nav-wrap {
    @include responsive(lg) {
      display: flex;
      align-items: center;
    }
  }

  &__nav {
    display: none;

    @include responsive(lg) {
      display: block;
    }
  }

  &__nav-list {
    @include responsive(lg) {
      display: flex;
    }

    @include responsive(xl) {
      margin-right: 20px;
    }
  }

  &__nav-item {
    @include responsive(lg) {
      padding-inline: 5px;
    }

    @include responsive(xl) {
      padding-inline: 0;

      &:not(:first-of-type) {
        padding-left: 40px;
      }
    }
  }

  &__nav-link {
    @include responsive(lg) {
      &:hover {
        opacity: 1;
        color: $c-accent;
      }
    }
  }
}

.l-header-top {
  padding-block: 36px 11px;
  background: url("../images/bg_header-top_sp.jpg") no-repeat center center / cover;

  @include responsive(md) {
    padding-block: 52px 18px;
    background-image: url("../images/bg_header-top_pc.jpg");
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    line-height: 27px;

    @include responsive(md) {
      font-size: 32px;
      line-height: 43px;
    }
  }
}
