@use '../../foundation/'as *;
@use '../../global/'as *;

.p-merit {
  padding-block: 30px 60px;

  @include responsive(md) {
    padding-block: 60px 100px;
  }

  &__top-wrap {
    @include responsive(md) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__img-wrap {
    @include responsive(md) {
      width: 40%;
    }
  }

  &__img-txt {
    margin-top: 30px;
    font-size: 18px;
    font-weight: bold;
    line-height: 40px;

    @include responsive(md) {
      margin-left: 30px;
    }
  }

  &__wrap {
    margin-top: 50px;

    @include responsive(md) {
      margin-top: 80px;
    }
  }
}
